import { navigate, useLocation } from '@reach/router';
import { addBreadcrumb, captureException } from '@sentry/browser';
import { useEffect, useReducer } from 'react';

import { parseTpPayload } from '../../utils/parseTpPayload';

type AuthState = {
  isAuthenticating: boolean;
  error: string | null;
};

type AuthAction =
  | {
      type: 'success';
    }
  | {
      type: 'error';
      error: string | null;
    };

const authStateReducer: React.Reducer<AuthState, AuthAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'success':
      return { isAuthenticating: false, error: null };
    case 'error':
      return {
        isAuthenticating: false,
        error: action.error,
      };
    default:
      return state;
  }
};

function savePayloadToLocalStorage(payload: string | null) {
  if (!payload) {
    addBreadcrumb({
      message: 'No payload to save to local storage',
      category: 'localStorage',
    });
    return;
  }

  try {
    localStorage.setItem('tpPrivateBookingPayload', payload);
  } catch {
    addBreadcrumb({
      message: 'Failed to save TP payload to local storage',
      category: 'localStorage',
    });
  }
}

export const useAuthenticate = () => {
  const location = useLocation();
  const tp = new URLSearchParams(location.search).get('tp');
  const [state, dispatch] = useReducer(authStateReducer, {
    isAuthenticating: true,
    error: null,
  });

  useEffect(() => {
    savePayloadToLocalStorage(tp);
    authenticateWithCalendarUrlPayload(tp)
      .then(() => {
        dispatch({ type: 'success' });
        void navigate('/private-booking/address');
      })
      .catch((e) => {
        captureException(e);
        dispatch({ type: 'error', error: 'Something went wrong' });
        void navigate('/private-booking');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
};

function authenticateWithCalendarUrlPayload(payload: string | null) {
  return new Promise((resolve, reject) => {
    if (!payload) {
      reject(new Error('No payload found'));
      return;
    }

    const { id, calendarUrlId, tpName } = parseTpPayload(payload);

    if (!id || !calendarUrlId || !tpName) {
      reject(new Error('Invalid payload'));
      return;
    }

    resolve();
  });
}
