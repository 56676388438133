import '../../../styles/components/private-booking.scss';

import React from 'react';
// import { useForm } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form/dist/index.ie11';

import { Button } from '../../button';
import { AppointmentDetails } from '../../marketplace/components/AppointmentDetails';
import { TextField } from '../../marketplace/components/TextField';
import { AppointmentData } from '../../marketplace/hooks/usePersistedState';

interface PrivateBookingResidentFormProps {
  appointmentData: AppointmentData;
  onSubmit: () => void;
  onChange: (nextState: AppointmentData) => void;
  isSubmitting: boolean;
  submitError?: string;
}

export const PrivateBookingResidentForm = ({
  appointmentData,
  onChange,
  onSubmit,
  isSubmitting,
  submitError,
}: PrivateBookingResidentFormProps) => {
  const form = useForm({ mode: 'onBlur', defaultValues: appointmentData });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, checked, value: targetValue } = e.target;
    const value = type === 'checkbox' ? checked : targetValue;

    onChange({
      ...appointmentData,
      [name]: value,
    });
  };

  return (
    <FormProvider {...form}>
      <form
        data-screen="resident_details"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="private-booking-resident-form">
          <div className="private-booking-resident-form--heading">
            Your{' '}
            <span className="private-booking-resident-form--heading--bold">
              contact details
            </span>
          </div>
          <TextField
            name="fullName"
            label="Full name"
            autoComplete="name"
            required
            onChange={handleChange}
          />
          <TextField
            name="email"
            label="Email address"
            type="email"
            autoComplete="email"
            required
            onChange={handleChange}
          />
          <TextField
            name="phone"
            label="Phone number"
            type="tel"
            autoComplete="mobile"
            required
            onChange={handleChange}
          />
        </div>

        <AppointmentDetails appointmentData={appointmentData} />

        <div className="private-booking-resident-form-submit">
          {submitError && (
            <div className="private-booking-resident-form-submit__error">
              {submitError}
            </div>
          )}

          <Button
            className="private-booking-resident-form-submit__button"
            variant="primary"
            type="submit"
            busy={isSubmitting}
          >
            Confirm booking
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
