import React from 'react';

import kantanLogo from '../../../assets/kantan-logo-capital.svg';

export const PrivateBookingFooter = () => {
  return (
    <div className="private-booking-footer">
      <span className="private-booking-footer__content">Powered by</span>
      <img
        src={kantanLogo}
        alt="Kantan Logo"
        className="private-booking-footer__logo"
      />
    </div>
  );
};
