import { navigate } from '@reach/router';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form/dist/index.ie11';

import { useGlobalRef } from '../../utils/global-state';
import { AddressInputGroup } from '../marketplace/components/AddressInputGroup';
import { JobTypeInputGroup } from '../marketplace/components/JobTypeInputGroup';
import {
  AppointmentData,
  usePersistedState,
} from '../marketplace/hooks/usePersistedState';
import { PRIVATE_AUTOBOOKING_JOB_TYPES } from '../marketplace/utils/jobTypes';

declare global {
  interface Window {
    dataLayer: object[];
  }
}

const useApplyParamsFromUrl = (
  setState: React.Dispatch<React.SetStateAction<AppointmentData>>,
) => {
  const hasAppliedParamsFromUrlRef = useGlobalRef('hasAppliedParamsFromUrlRef');

  if (!hasAppliedParamsFromUrlRef.current) {
    hasAppliedParamsFromUrlRef.current = true;
    applyParamsFromUrl();
  }

  function applyParamsFromUrl() {
    const q = new URLSearchParams(window.location.search);
    const jobType = q.get('jobType');
    if (jobType) {
      setState((state) => ({ ...state, jobType }));
    }
  }
};

export const AddressStep: React.FC = () => {
  const [state, setState] = usePersistedState('pjAppointmentData');
  useApplyParamsFromUrl(setState);

  const form = useForm({
    mode: 'onBlur',
    defaultValues: state,
  });

  const handleSubmit = () => {
    navigate('appointment');
  };

  const shouldDisableEverythingExceptJobType = !state.jobType;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        style={{ paddingTop: 10 }}
        data-screen="resident_address"
      >
        <JobTypeInputGroup
          jobTypes={PRIVATE_AUTOBOOKING_JOB_TYPES}
          jobType={state.jobType}
          onJobTypeChange={(jobType) => setState({ ...state, jobType })}
        />

        <div
          style={
            shouldDisableEverythingExceptJobType
              ? {
                  opacity: 0.2,
                  pointerEvents: 'none',
                }
              : undefined
          }
        >
          <AddressInputGroup
            state={state}
            setState={setState}
            resetForm={form.reset}
            shouldAutoFocusPostcodeLookup={
              shouldDisableEverythingExceptJobType ? false : undefined
            }
          />
        </div>
      </form>
    </FormProvider>
  );
};
