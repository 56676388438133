import { navigate, Redirect } from '@reach/router';
import React, { useState } from 'react';

import { createPrivateBookingAppointment } from '../../services/kantan-client';
import {
  AppointmentData,
  usePersistedState,
} from '../marketplace/hooks/usePersistedState';
import { PrivateBookingResidentForm } from './components/PrivateBookingResidentForm';

export const PrivateBookingResident = () => {
  const [appointmentData, setAppointmentData] = usePersistedState(
    'pjAppointmentData',
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string>();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setSubmitError(undefined);

    try {
      await createPrivateBookingAppointment(appointmentData);
      await navigate('confirmation');
    } catch (e) {
      setSubmitError(e.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (nextState: AppointmentData) => {
    setAppointmentData(nextState);
    setSubmitError(undefined);
  };

  if (!appointmentData.timeSlot) {
    return <Redirect to="/private-booking/address" />;
  }

  return (
    <PrivateBookingResidentForm
      appointmentData={appointmentData}
      onChange={handleChange}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
      submitError={submitError}
    />
  );
};
