import '../../styles/components/private-booking.scss';

import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';

import { useGlobalState } from '../../utils/global-state';
import { AppointmentDetails } from '../marketplace/components/AppointmentDetails';
import {
  AppointmentData,
  defaultValue,
  usePersistedState,
} from '../marketplace/hooks/usePersistedState';

export const PrivateBookingConfirmation = () => {
  const [tpName, setStateProperty] = useGlobalState('tpName');
  const [
    localStorageAppointmentData,
    setLocalStorageAppointmentData,
  ] = usePersistedState('pjAppointmentData');

  const [appointment, setAppointment] = React.useState<AppointmentData>();
  const [tradespersonName, setTradespersonName] = React.useState('');

  useEffect(() => {
    if (!tpName) {
      const tpNameFromStorage = JSON.parse(
        atob(localStorage.getItem('tpPrivateBookingPayload') ?? ''),
      )?.tpName;

      if (tpNameFromStorage) {
        setTradespersonName(tpNameFromStorage);
      }
    } else {
      setTradespersonName(tpName);
      setStateProperty('');
    }

    if (localStorageAppointmentData.timeSlot) {
      setAppointment(localStorageAppointmentData);
      setLocalStorageAppointmentData(defaultValue);
    }
  }, [
    localStorageAppointmentData,
    setLocalStorageAppointmentData,
    tpName,
    setStateProperty,
  ]);

  return (
    <>
      <div>✅ Thank you for booking with {tradespersonName} </div>

      {appointment && <AppointmentDetails appointmentData={appointment} />}

      <Box
        mt="20px"
        color="#292B2E"
        fontSize="14px"
        lineHeight="22px"
        letterSpacing="-0.02em"
      >
        <Box component="p" mb="20px">
          Please check your inbox for an email with your appointment
          confirmation and detailed information.
        </Box>

        <Box component="p" fontWeight="700">
          If you don&apos;t receive an email{' '}
          <span className="private-booking-resident-form-confirmation--spam">
            please check your spam folder.
          </span>
        </Box>
      </Box>
    </>
  );
};
