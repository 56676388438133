import '../styles/components/private-jobs.scss';

import { RouteComponentProps, Router, useLocation } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { HeaderNew } from '../components/marketplace/components/HeaderNew';
import { PageNotFound } from '../components/page-not-found';
import { AddressStep } from '../components/private-autobooking/AddressStep';
import { PrivateBookingFooter } from '../components/private-autobooking/components/PrivateBookingFooter';
import { PrivateBookingAppointment } from '../components/private-autobooking/PrivateBookingAppointment';
import { PrivateBookingConfirmation } from '../components/private-autobooking/PrivateBookingConfirmation';
import { PrivateBookingResident } from '../components/private-autobooking/PrivateBookingResident';
import { useAuthenticate } from '../components/private-autobooking/useAuthenticate';
import { GlobalStateProvider } from '../utils/global-state';
import { parseTpPayload } from '../utils/parseTpPayload';

const PageNotFoundRoute: React.FC<RouteComponentProps> = () => <PageNotFound />;

const BookingRoute: React.FC<RouteComponentProps> = () => <AddressStep />;

const AppointmentRoute: React.FC<RouteComponentProps> = () => (
  <PrivateBookingAppointment />
);

const ResidentRoute: React.FC<RouteComponentProps> = () => (
  <PrivateBookingResident />
);

const ConfirmationRoute: React.FC<RouteComponentProps> = () => (
  <PrivateBookingConfirmation />
);

const BookingPage = () => {
  const [tpName, setTpName] = useState('');
  const location = useLocation();
  useAuthenticate();

  useEffect(() => {
    const tpPayload = localStorage.getItem('tpPrivateBookingPayload');

    let tpNameFromStorage;
    if (tpPayload) {
      tpNameFromStorage = parseTpPayload(tpPayload)?.tpName;
    }

    if (tpNameFromStorage) {
      setTpName(tpNameFromStorage);
    }
  }, []);

  const renderHeader = () => {
    if (location.pathname === '/private-booking/confirmation') {
      return null;
    }

    return <HeaderNew />;
  };

  return (
    <GlobalStateProvider>
      <Helmet
        titleTemplate="Book a service with %s"
        title={tpName}
        defaultTitle="Kantan"
      />
      <div
        id="theme-root"
        className={`theme-kantan`}
        style={{
          minHeight: '100vh',
          position: 'relative',
          backgroundColor: '#eff4fc',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <header className="private-booking-header">
          <span className="private-booking-header__text">{tpName}</span>
          <span className="private-booking-header__text">Booking Portal</span>
        </header>
        <section className="private-booking-section">
          {renderHeader()}
          <Router basepath={`/private-booking`}>
            <BookingRoute path="address" />
            <ResidentRoute path="resident" />
            <AppointmentRoute path="appointment" />
            <ConfirmationRoute path="confirmation" />
            <PageNotFoundRoute default />
          </Router>
        </section>
        <PrivateBookingFooter />
      </div>
    </GlobalStateProvider>
  );
};

export default BookingPage;
