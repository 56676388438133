import { addBreadcrumb, captureException, Severity } from '@sentry/browser';

import {
  BookableSlotResponse,
  fetchPrivateBookableSlots,
  fetchPrivateBookingConfiguration,
  fetchPrivateBookingSlotRating,
} from '../../services/kantan-client';
import { isFarEnoughInFutureForBooking } from '../calendar/slot-filters';
import { useAsyncResult } from '../calendar/use-async-result';
import {
  CalculatedAvailability,
  SlotAvailability,
} from '../calendar/use-bookable-slots';

interface PrivateBookableSlotsParams {
  startDateTime: string;
  endDateTime: string;
  address: string;
  jobType: string;
}

export const usePrivateBookableSlots = ({
  startDateTime,
  endDateTime,
  address,
  jobType,
}: PrivateBookableSlotsParams): [
  SlotAvailability | undefined,
  boolean,
  Error | undefined,
  () => Promise<void>,
] => {
  const [
    slotAvailability,
    isLoading,
    error,
    refetch,
  ] = useAsyncResult(async () => {
    if (!startDateTime || !endDateTime) {
      return;
    }

    addBreadcrumb({
      category: 'bookable-slots',
      message: 'Fetching private booking slots',
      data: { startDateTime, endDateTime },
    });

    const configuration = await fetchPrivateBookingConfiguration();
    const availabilities = await fetchPrivateBookableSlots(
      startDateTime,
      endDateTime,
    );
    const rejectedRatingSlots: BookableSlotResponse[] = [];

    const slotsWithAvailability = availabilities.map(async (availability) => {
      let calculatedAvailability;
      try {
        calculatedAvailability = await calculateAvailability({
          slot: availability,
          address,
          jobType,
          slotRatingThreshold: configuration.SLOT_RATING.recommendedThreshold,
          onRatingRejection: (slot) => rejectedRatingSlots.push(slot),
        });
      } catch (error) {
        addBreadcrumb({
          category: 'bookable-slots',
          message: 'Failed to calculate availability',
          data: { error },
        });
        captureException(error, { level: Severity.Warning });
        calculatedAvailability = { isAvailable: false };
      }

      const {
        isAvailable,
        isRatingGood,
        appointmentsCount,
      } = calculatedAvailability;

      return {
        startDateTime: new Date(availability.startDateTime),
        endDateTime: new Date(availability.endDateTime),
        isAvailable,
        isRatingGood,
        appointmentsCount,
      };
    });

    const bookableSlots = await Promise.all(slotsWithAvailability);

    addBreadcrumb({
      category: 'bookable-slots',
      message: 'Successfully fetched bookable slots',
      data: {
        bookableSlots: bookableSlots.length,
        rejectedRatingSlots: rejectedRatingSlots.length,
      },
    });
    return {
      bookableSlots,
      rejectedRatingSlots: [],
    };
  }, [startDateTime, endDateTime, address, jobType]);

  return [slotAvailability, isLoading, error, refetch];
};

interface CalculateAvailabilityParams {
  slot: BookableSlotResponse;
  address: string;
  jobType: string;
  slotRatingThreshold: number;
  onRatingRejection?: (slot: BookableSlotResponse) => void;
}

async function calculateAvailability({
  slot,
  address,
  jobType,
  slotRatingThreshold,
  onRatingRejection,
}: CalculateAvailabilityParams): Promise<CalculatedAvailability> {
  const {
    isAvailable,
    isFullyBooked,
    appointments,
    startDateTime,
    endDateTime,
  } = slot;

  // Not available
  if (
    !isFarEnoughInFutureForBooking(new Date(startDateTime)) ||
    !isAvailable ||
    isFullyBooked
  ) {
    return { isAvailable: false };
  }

  // Check the rating of slot which uses the context of adjacent slots even if empty
  const rating = await fetchPrivateBookingSlotRating({
    startDateTime: new Date(startDateTime),
    endDateTime: new Date(endDateTime),
    address,
    jobType,
  });

  const isRatingGood = rating.score >= slotRatingThreshold;
  if (!isRatingGood && onRatingRejection) {
    onRatingRejection(slot);
  }

  return {
    isAvailable: isRatingGood,
    isRatingGood: appointments.length > 0 && isRatingGood,
    appointmentsCount: appointments.length,
  };
}
