type ParsedTradespersonPayload = {
  id?: string;
  calendarUrlId?: string;
  tpName?: string;
};

export function parseTpPayload(
  tpPayload: string | null,
): ParsedTradespersonPayload {
  if (!tpPayload) {
    return {};
  }

  try {
    return JSON.parse(atob(tpPayload));
  } catch (e) {
    return {};
  }
}
