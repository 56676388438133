import '../../styles/components/marketplace.scss';

import { navigate } from '@reach/router';
import { addWeeks, endOfWeek, formatISO, startOfWeek } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { configAxiosForAvailabilities } from '../../services/kantan-client';
import { useGlobalState } from '../../utils/global-state';
import { parseTpPayload } from '../../utils/parseTpPayload';
import {
  trackBestSlotModalDismissed,
  trackBestSlotModalViewed,
} from '../calendar';
import { CalendarBestSlot } from '../calendar/calendar-best-slot-modal';
import {
  AppointmentValues,
  CalendarForm,
} from '../calendar/calendar-form-new/calendar-form-new';
import { extractDaySlots } from '../calendar/calendar-form-new/calendar-input';
import {
  getBestBookableSlot,
  sortByStart,
  toTimeSlotValue,
} from '../calendar/utils';
import { getAddress } from '../marketplace/components/AppointmentPreview';
import { usePersistedState } from '../marketplace/hooks/usePersistedState';
import { usePrivateBookableSlots } from './usePrivateBookableSlots';

const slotLabels = [
  {
    name: 'Morning',
    time: '8am-12pm',
  },
  {
    name: 'Afternoon',
    time: '12pm-4pm',
  },
  {
    name: 'Evening',
    time: '4pm-8pm',
  },
];

export const PrivateBookingAppointment = () => {
  const [showRecommendedSlot, setShowRecommendedSlot] = useState(true);
  const [, setStateProperty] = useGlobalState('tpName');

  const [appointmentData, setAppointmentData] = usePersistedState(
    'pjAppointmentData',
  );

  useLayoutEffect(() => {
    const tpPayload = localStorage.getItem('tpPrivateBookingPayload');
    const tpName = parseTpPayload(tpPayload)?.tpName;
    tpName && setStateProperty(tpName);

    if (tpPayload) {
      configAxiosForAvailabilities({ tpPayload });
    }
  }, [setStateProperty]);

  const now = new Date();

  const [
    slotAvailability,
    isSlotsLoading,
    slotError,
    refetchSlots,
  ] = usePrivateBookableSlots({
    startDateTime: formatISO(
      zonedTimeToUtc(startOfWeek(now, { weekStartsOn: 1 }), 'UTC'),
    ),
    endDateTime: formatISO(zonedTimeToUtc(endOfWeek(addWeeks(now, 2)), 'UTC')),
    address: getAddress(appointmentData),
    jobType: appointmentData.jobType,
  });

  const sortedSlots = sortByStart(slotAvailability?.bookableSlots ?? []);
  const [bestSlot] = getBestBookableSlot(sortedSlots);

  const handleSubmit = async (value: AppointmentValues) => {
    setAppointmentData({ ...appointmentData, timeSlot: value.timeSlot });
    await navigate('resident');
  };

  useEffect(() => {
    if (slotAvailability && !bestSlot) {
      console.log('track page viewed');
      // TODO: Track page view
    } else if (bestSlot) {
      trackBestSlotModalViewed(bestSlot);
    }
  }, [slotAvailability, bestSlot]);

  const dismissBestSlotModal = () => {
    setShowRecommendedSlot(false);
    if (bestSlot) trackBestSlotModalDismissed(bestSlot);
    // TODO: Track page view
    // if (appointment && slotAvailability) {
    //   trackCalendarPageViewed(
    //     appointment.id,
    //     slotsShownPerWeek,
    //     slotAvailability?.rejectedRatingSlots.length,
    //     slotStartTimes,
    //     slotEndTimes,
    //   );
    // }
  };

  const submitBookingBestSlot = async () => {
    if (!bestSlot) {
      return;
    }

    const {
      date,
      slots: [{ time }],
    } = extractDaySlots([bestSlot]);

    const timeSlot = toTimeSlotValue({ date, slot: time });
    setAppointmentData({ ...appointmentData, timeSlot });
    await navigate('resident');
  };

  return (
    <section className="marketplace" data-screen="calendar_this_week">
      {showRecommendedSlot && bestSlot ? (
        <CalendarBestSlot
          slot={bestSlot}
          onSubmit={submitBookingBestSlot}
          onCancel={dismissBestSlotModal}
          isSubmitting={false}
        />
      ) : (
        <CalendarForm
          appointmentData={appointmentData}
          defaultValues={{ timeSlot: appointmentData.timeSlot }}
          slotAvailability={slotAvailability}
          slotLabels={slotLabels}
          submitBooking={handleSubmit}
          isSlotsLoading={isSlotsLoading}
          slotError={slotError}
          isPrivateBooking={true}
        />
      )}
    </section>
  );
};
